import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Contact from '../../components/Contact/index';
import './index.css';

const pageTitle = "Contact Devetry's Team of Software Professionals | Devetry";
const pageDescription = '';

const ContactPageTemplate = () => {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="root-container">
        <Contact />
      </main>
    </>
  );
};

const ContactPage = ({ data }) => {
  return <ContactPageTemplate />;
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Contact/index" } }) {
      frontmatter {
        templateKey
      }
    }
  }
`;
