import React, { useEffect, useRef } from 'react';
import { useCompleteOnScreen } from '../../utils/hooks';

import { AnimatedDots } from '../Dots';
import './index.css';
import background from '../../img/contact-zebra-background.svg';
import contact from '../../img/drop-a-line.svg';
import denver from '../../img/denver-icon.svg';
import portland from '../../img/portland-icon.svg';
import ContactForm from '../../components/ContactForm';

const Contact = () => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  return (
    <section className="contact-container root-padding">
      <img className="background" src={background} alt="background" />
      <div className="left">
        <div className="top">
          <div>
            <p className="large-content">Drop a line</p>
            <AnimatedDots completeOnScreen={isCompleteOnScreen} />
          </div>
          <div className="illustration-img">
            <img src={contact} alt="contact" />
          </div>
        </div>
        <div className="locations">
          <div className="is-flex-direction-column">
            <img src={denver} alt="Denver" />
            <p>
              <strong>Denver</strong>
            </p>
            <a
              href="https://www.google.com/maps/place/Devetry/@39.770774,-104.9704646,15z/data=!4m5!3m4!1s0x0:0x78f6ae4956e35462!8m2!3d39.7709275!4d-104.9706682"
              target="_blank"
              rel="noreferrer"
            >
              3858 Walnut St <br></br> Denver, CO 80205
            </a>
          </div>
          <div className="is-flex-direction-column">
            <img src={portland} alt="Portland" />
            <p>
              <strong>Portland</strong>
            </p>
            <a
              href="https://www.google.com/maps/place/Industrious+Pioneer+Square/@45.5183169,-122.6815708,17z/data=!3m1!4b1!4m5!3m4!1s0x54950b5ccefae4b9:0x6774fc2d76ad26e5!8m2!3d45.51835!4d-122.6793692"
              target="_blank"
              rel="noreferrer"
            >
              811 SW 6th Ave Suite 1000 <br></br> Portland, OR 97204
            </a>
          </div>
        </div>
      </div>
      <div ref={ref} className="right">
        <span className="decoration"></span>
        <ContactForm />
      </div>
    </section>
  );
};

export default Contact;
