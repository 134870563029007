import React from 'react';

import './index.css';

const Dots = ({ className = '', amount, color }) => {
  const renderDots = () => {
    const dots = [];
    for (let index = 0; index < amount; index++) {
      dots.push(
        <div
          key={index}
          className="dot"
          style={color ? { backgroundColor: color } : {}}
        ></div>
      );
    }
    return dots;
  };
  return <div className={`${className} dots`}>{renderDots()}</div>;
};

export const AnimatedDots = ({ completeOnScreen, color }) => (
  <div className="cropper">
    <Dots
      amount={8}
      color={color}
      className={`animated-dots ${completeOnScreen ? 'after' : ''}`}
    />
  </div>
);

export default Dots;
